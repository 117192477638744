import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex items-center gap-x-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ImageIcon = _resolveComponent("ImageIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_CoverSettings = _resolveComponent("CoverSettings");
  const _component_PuzzleIcon = _resolveComponent("PuzzleIcon");
  const _component_MenuIcon = _resolveComponent("MenuIcon");
  const _component_EntryContextmenu = _resolveComponent("EntryContextmenu");
  const _component_PSOverlay = _resolveComponent("PSOverlay");
  const _component_TemplateWrapper = _resolveComponent("TemplateWrapper");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$props.entry.cover ? (_openBlock(), _createBlock(_component_CoverSettings, {
    key: 0,
    modelValue: $props.entry.cover,
    entry: $props.entry
  }, {
    button: _withCtx(() => [_createVNode(_component_PSButton, {
      title: "Set Cover Image"
    }, {
      default: _withCtx(() => [_createVNode(_component_ImageIcon, {
        size: "18"
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue", "entry"])) : _createCommentVNode("", true), !$props.entry.output && !$props.entry.input && $props.entry.links.length <= 1 ? (_openBlock(), _createBlock(_component_PSButton, {
    key: 1,
    onClick: _cache[0] || (_cache[0] = $event => $data.showTemplates = !$data.showTemplates),
    variant: "round",
    color: "white",
    title: "Show Templates"
  }, {
    default: _withCtx(() => [_createVNode(_component_PuzzleIcon, {
      size: "16"
    })]),
    _: 1
  })) : _createCommentVNode("", true), _createVNode(_component_PSOverlay, {
    id: "contextmenu"
  }, {
    button: _withCtx(() => [_createVNode(_component_PSButton, {
      title: "Page Settings"
    }, {
      default: _withCtx(() => [_createVNode(_component_MenuIcon, {
        size: "20"
      })]),
      _: 1
    })]),
    default: _withCtx(({
      close
    }) => [_createVNode(_component_EntryContextmenu, {
      ref: "configuration",
      modelValue: $props.entry,
      hide: ['timestamps'],
      position: "settings",
      onClose: close
    }, null, 8, ["modelValue", "onClose"])]),
    _: 1
  }), $data.showTemplates ? (_openBlock(), _createBlock(_component_TemplateWrapper, {
    key: 2,
    modelValue: $props.entry,
    onClose: _cache[1] || (_cache[1] = $event => $data.showTemplates = false)
  }, null, 8, ["modelValue"])) : _createCommentVNode("", true)]);
}