import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = ["onClick"];
const _hoisted_2 = ["onClick"];
const _hoisted_3 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_UserIcon = _resolveComponent("UserIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_PSListItem = _resolveComponent("PSListItem");
  const _component_router_link = _resolveComponent("router-link");
  const _component_PSList = _resolveComponent("PSList");
  const _component_PSOverlay = _resolveComponent("PSOverlay");
  return _openBlock(), _createBlock(_component_PSOverlay, null, {
    button: _withCtx(() => [_createVNode(_component_PSButton, {
      ref: "userMenuButton",
      class: _normalizeClass(_ctx.$style.userMenuButton),
      title: "Open user menu"
    }, {
      default: _withCtx(() => [_cache[0] || (_cache[0] = _createElementVNode("span", {
        class: "sr-only"
      }, "Open user menu", -1)), _createVNode(_component_UserIcon, {
        size: "24",
        "stroke-width": "2"
      })]),
      _: 1
    }, 8, ["class"])]),
    title: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode(" Profile ")])),
    default: _withCtx(({
      close
    }) => [_createVNode(_component_PSList, null, {
      default: _withCtx(() => [_ctx.$route.meta.showSidebar ? (_openBlock(), _createBlock(_component_PSListItem, {
        key: 0
      }, {
        default: _withCtx(({
          active
        }) => [_createElementVNode("a", {
          onClick: $event => (_ctx.$router.push({
            name: 'profile'
          }), close()),
          class: _normalizeClass([active ? 'bg-gray-50 dark:bg-neutral-900' : '', 'block w-full cursor-pointer px-3 py-1 text-sm leading-6 text-gray-900 dark:text-neutral-300'])
        }, " Profile ", 10, _hoisted_1)]),
        _: 2
      }, 1024)) : _createCommentVNode("", true), _ctx.$route.meta.showSidebar && _ctx.$store.getters.entries.filter(e => e.routine).length ? (_openBlock(), _createBlock(_component_PSListItem, {
        key: 1
      }, {
        default: _withCtx(({
          active
        }) => [_createElementVNode("a", {
          onClick: $event => (_ctx.$router.push({
            name: 'routines'
          }), close()),
          class: _normalizeClass([active ? 'bg-gray-50 dark:bg-neutral-900' : '', 'block w-full cursor-pointer px-3 py-1 text-sm leading-6 text-gray-900 dark:text-neutral-300'])
        }, " Routines ", 10, _hoisted_2)]),
        _: 2
      }, 1024)) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.helpItems, (item, index) => {
        return _openBlock(), _createBlock(_component_PSListItem, {
          key: index,
          as: "div"
        }, {
          default: _withCtx(({
            active
          }) => [_createElementVNode("a", {
            href: item.url,
            target: "_blank",
            class: _normalizeClass([active ? 'bg-gray-50 dark:bg-neutral-900' : '', 'block w-full px-3 py-1 text-sm leading-6 text-gray-900 dark:text-neutral-300'])
          }, _toDisplayString(item.title), 11, _hoisted_3)]),
          _: 2
        }, 1024);
      }), 128)), _createVNode(_component_PSListItem, null, {
        default: _withCtx(({
          active
        }) => [$options.token ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: {
            name: 'logout'
          },
          class: _normalizeClass([active ? 'bg-gray-50 dark:bg-neutral-900' : '', 'block w-full px-3 py-1 text-sm leading-6 text-gray-900 dark:text-neutral-300'])
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode(" Logout ")])),
          _: 2
        }, 1032, ["class"])) : _createCommentVNode("", true)]),
        _: 1
      })]),
      _: 2
    }, 1024)]),
    _: 1
  });
}