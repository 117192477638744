import { renderSlot as _renderSlot, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = ["disabled"];
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = {
  key: 0,
  class: "inline-flex h-5 min-w-5 items-center justify-center gap-x-0.5 rounded border border-neutral-400 bg-white px-0.5 font-semibold text-neutral-900 dark:border-neutral-600 dark:bg-black dark:text-neutral-200"
};
const _hoisted_4 = {
  key: 0
};
import { ref } from "vue";
import { useFloating, shift, flip, autoUpdate, offset } from "@floating-ui/vue";

// import { CircleHelp } from "lucide-vue-next";
const __default__ = {
  components: {
    // CircleHelp,
  },
  props: {
    disabled: Boolean,
    title: String,
    shortcode: String,
    shortcodeMeta: Boolean
  },
  data() {
    return {
      navigator: navigator,
      help: false
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("keydown", this.onShortcode);
    this.$emit("mounted");
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onShortcode);
    this.$emit("beforeUnmount");
  },
  methods: {
    onShortcode(event) {
      if (!this.shortcode || event.key !== this.shortcode || event.repeat) return;
      const element = this.$el;
      const isVisible = !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
      const isNotObscured = !this.isElementObscured(element);
      if ((this.$Cypress || (isVisible || event.key == "Enter") && (isNotObscured || event.key == "Enter" || event.metaKey)) && (this.shortcode == "Enter" && event.key == "Enter" || this.shortcode == "Escape" && event.key == "Escape" || (this.shortcodeMeta && event.metaKey || event.target.tagName !== "INPUT" && event.target.isContentEditable !== true) && !this.disabled)) {
        if (event.key === this.shortcode) {
          event.preventDefault();
          event.stopPropagation();
          this.$el.click();
        }
      }
    },
    isElementObscured(element) {
      const rect = element.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      const topElement = document.elementFromPoint(centerX, centerY);
      return topElement !== element && !element.contains(topElement);
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'PSButton',
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      placement: "bottom-center",
      whileElementsMounted: autoUpdate,
      middleware: [offset(2), flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("button", {
        ref_key: "reference",
        ref: reference,
        type: "button",
        disabled: __props.disabled,
        class: _normalizeClass([_ctx.$style.buttonComponent, "group/button"]),
        "data-hide-from-share": ""
      }, [_renderSlot(_ctx.$slots, "default"), _renderSlot(_ctx.$slots, "title", {}, () => [__props.title && !_ctx.navigator.userAgent.includes('Mobile') ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "floating",
        ref: floating,
        style: _normalizeStyle(_unref(floatingStyles)),
        class: "pointer-events-none z-10 mx-auto flex w-max translate-y-full items-center gap-2 text-ellipsis whitespace-nowrap rounded-md bg-white px-2 py-1 text-left text-xs text-neutral-700 opacity-0 shadow-md group-hover/button:opacity-100 group-hover/button:transition-opacity group-hover/button:delay-300 dark:bg-black dark:text-neutral-300 dark:ring-1 dark:ring-neutral-700 dark:drop-shadow-none"
      }, [_createElementVNode("span", {
        innerHTML: __props.title
      }, null, 8, _hoisted_2), __props.shortcode ? (_openBlock(), _createElementBlock("kbd", _hoisted_3, [__props.shortcodeMeta ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$metaKey), 1)) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(__props.shortcode.toUpperCase()), 1)])) : _createCommentVNode("", true)], 4)) : _createCommentVNode("", true)])], 10, _hoisted_1);
    };
  }
});